export const APP = "/app"
export const APP_HOME = APP + "/dashboard";
export const LOGIN = "/";
export const SHARED_PASSWORD_LINK = "/shared";
export const PRICING = "/pricing";

const navigation = [
  {
    name: "Dashboard",
    href: APP_HOME,
    current: false,
    show: false,
  },
];

export default navigation;
