import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { LoadingSpinner } from "../Commons";
import userServices from "../../services/userServices";
import groupService from "../../services/groupService";
import { onSnapshot, query, where } from "firebase/firestore";
import { useFormik } from "formik";
import ReactGA from "react-ga4";
import * as Yup from "yup";

const ShareGroup = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isRemoving, setIsRemoving] = useState();
    const [groupOwners, setGroupOwners] = useState([]);

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            email: Yup.string().email('Enter valid email').required('Please enter email'),
        }),
        onSubmit: async (values) => {
            setIsLoading(true);
            const groupId = props.group.id;
            const email = values['email'];
            groupService.addGroupOwner(groupId, email).then((group) => {
                generateAndSetGroupOwners(group.owners);
                setIsLoading(false);
                formik.resetForm();
            }).catch(err => {
                setIsLoading(false);
            });
            ReactGA.event({
                category: "feature",
                action: "Share group",
            });
        }
    })
    const generateAndSetGroupOwners = (owners) => {
        const user = userServices.getUser();
        owners = owners.filter((owner) => {
            return user.email.trim() != owner.trim()
        });
        setGroupOwners(owners);
    }
    const handleCancel = () => {
        formik.resetForm();
        props.handleCancel(null);
    };

    const removeGroupOwner = (email) => {
        setIsRemoving(email);
        groupService.removeGroupOwner(props.group.id, email).then((group) => {
            generateAndSetGroupOwners(group.owners);
            setIsRemoving();
            generateAndSetGroupOwners()
        }).catch(err => setIsRemoving())
        ReactGA.event({
            category: "feature",
            action: "Unshare group",
        });
    }

    useEffect(() => {
        const user = userServices.getUser();
        let group_query = query(
            groupService.groupRef,
            where("owners", "array-contains", user.email),
        );
        const unsubscribeGroup = onSnapshot(group_query, snapshot => {
            snapshot.docs.map(doc => {
                if (doc.id == props.group.id) {
                    const docData = doc.data();
                    generateAndSetGroupOwners(docData.owners);
                }
            });
        });
        return () => {
            unsubscribeGroup();
        }
    }, [])

    const groupOwnersList = () => {
        return (
            <div className={`${groupOwners.length > 0 ? 'block' : 'hidden'}`}>
                <div className="overflow-x-auto relative shadow-sm sm:rounded-lg my-4">
                    <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                            <tr>
                                <th scope="col" className="py-3 px-4" colSpan={2}>
                                    People with access
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                groupOwners.map((owner, idx) => {
                                    return <tr className="bg-white border-b " key={`group-owner-${idx}`}>
                                        <td scope="row" className="py-3 px-4 text-gray-900 whitespace-nowrap ">
                                            {owner}
                                            {
                                                owner == props.group.createdByEmail &&
                                                <span className="text-gray-400 text-xs px-1">(Owner)</span>
                                            }
                                        </td>
                                        <td className="py-3 px-4 text-right">
                                            <div
                                                onClick={() => removeGroupOwner(owner)}
                                                className="font-medium text-red-600 cursor-pointer"
                                            >
                                                {isRemoving === owner ? <LoadingSpinner /> : 'Remove'}
                                            </div>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    const addOwerForm = () => {
        return (
            <form onSubmit={formik.handleSubmit}>
                <div className="bg-white mx-6 my-3">
                    <div className="sm:flex sm:items-start">
                        <div className="mt-3 w-full text-left">
                            <Dialog.Title
                                as="h3"
                                className="text-lg leading-6 font-medium text-gray-900"
                            >
                                Share group
                                <p className="text-sm font-normal mt-1 text-gray-500">Sharing group will share all credentials within it</p>
                            </Dialog.Title>
                            <div className="mt-2 w-full">
                                <div className="flex items-baseline w-full">
                                    <div className="w-full">
                                        <input
                                            className="appearance-none block w-[380px] text-sm placeholder:text-sm text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            id="email"
                                            type="text"
                                            placeholder="Enter email address"
                                            name="email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                        />
                                        <p className="text-red-500 text-xs mt-1 mb-2">
                                            {formik.errors.email
                                                ? formik.errors.email
                                                : ""}
                                        </p>
                                    </div>
                                    <button
                                        type="submit"
                                        className="w-full inline-flex text-sm justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-800 hover:bg-primary-900 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    >
                                        {isLoading ? <LoadingSpinner /> : "Share"}
                                    </button>
                                </div>
                                {groupOwnersList()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={handleCancel}
                    >
                        Done
                    </button>
                </div>
            </form>
        )
    }
    return (
        <Transition.Root show={props.show} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => null}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                                {addOwerForm()}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default ShareGroup;