import { Route, Routes } from "react-router-dom";
import { Home, Pricing } from "./pages";
import { Home as AppHome, SharePassword } from "./pages/app";
import { AppLayout } from "./components";
import { NotFound } from "./components/Commons";
import * as routes from "./constants/appNavigations";
import PublicLayout from "./components/Layout/PublicLayouts";

function App() {
  return (
    <div className="App h-full bg-gray-50">
      <Routes>
        <Route path="" element={<PublicLayout />}>
          <Route path={routes.LOGIN} element={<Home />} />
          <Route path={routes.PRICING} element={<Pricing />} />
        </Route>
        <Route path={routes.APP} element={<AppLayout />}>
          <Route path={routes.APP_HOME} element={<AppHome />} />
        </Route>
        <Route path={routes.SHARED_PASSWORD_LINK + "/:linkId"} element={<SharePassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
