import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Disclosure, Menu } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import appNavigation from "../../constants/appNavigations";
import userServices from "../../services/userServices";
import UserBadge from "./UserBadge";
import ReactGA from "react-ga4";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar({ navigation = appNavigation }) {
  const [user, setUser] = useState(userServices.getUser());
  const navigate = useNavigate();

  const handleSignout = () => {
    ReactGA.event({
      category: "feature",
      action: "Sign out",
    });
    userServices.logoutUser();
    navigate("/");
  };

  return (
    <div className="fixed w-full z-50">
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">

                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <a href="/">
                      <h1 className="md:text-3xl sm:text-3x font-extrabold tracking-tight text-white">
                        PassGuard
                      </h1>
                    </a>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {
                        navigation.map((item) => (
                          item.show ?
                            <a
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-gray-900 text-white"
                                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                "px-3 py-2 rounded-md text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </a>
                            : null
                        ))
                      }
                    </div>
                  </div>
                </div>

                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">

                    <UserBadge name={user.name} email={user.email} photo={user.photo} />
                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <button
                          type="button"
                          onClick={handleSignout}
                          className="text-white bg-red-700 rounded text-sm py-2 px-5">
                          Sign Out
                        </button>
                      </div>
                    </Menu>
                  </div>
                </div>

                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>

              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigation.map((item) => (
                  item.show ?
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "block px-3 py-2 rounded-md text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                    : null
                ))}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-700">
                <div className="flex items-center p-3">
                  <UserBadge name={user.name} email={user.email} photo={user.photo} />
                </div>
                <div className="mt-3 px-2 space-y-1">
                  <button
                    type="button"
                    onClick={handleSignout}
                    className="text-white bg-red-700 rounded text-sm py-2 px-5">
                    Sign Out
                  </button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
