import React from "react";
import logo from "../../../assets/branding/logo-short.png"

const Footer = () => {
    return (
        <footer className="p-4 mt-20 bg-white sm:p-6 shadow shadow-gray-300">
            <div className="mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <div className="md:flex md:justify-between">
                    <div className="mb-6 md:mb-0">
                        <a href="/" className="flex items-center">
                            <img src={logo} className="mr-3 h-8" alt="Passguard" />
                            <span className="self-center text-2xl font-semibold whitespace-nowrap">PassGuard</span>
                        </a>
                    </div>
                    <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-2">
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase">Resources</h2>
                            <ul className="text-gray-600">
                                <li className="mb-3">
                                    <a href="/" className="hover:underline">Security</a>
                                </li>
                                <li className="mb-3">
                                    <a href="/" className="hover:underline">How It Works</a>
                                </li>
                                <li>
                                    <a href="/" className="hover:underline">Premium vs Free</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">Legal</h2>
                            <ul className="text-gray-600 ">
                                <li className="mb-4">
                                    <a href="/" className="hover:underline">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="/" className="hover:underline">Terms &amp; Conditions</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="my-4 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-6" />
                <div className="sm:flex sm:items-center sm:justify-between">
                    <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2022 <a href="/" className="hover:underline">PassGuard</a>. All Rights Reserved.
                    </span>
                   
                </div>
            </div>
        </footer>
    )
}
export default Footer;