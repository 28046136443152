import React from "react";

const Footer = () => {
    return (
        <footer className="p-4 bg-white rounded-lg shadow md:flex md:items-center md:justify-between md:p-6 ">
            <span className="text-sm text-gray-500 sm:text-center">
                © {new Date().getFullYear()} <a href="#" className="hover:underline">PassGuard</a>. All Rights Reserved.
            </span>
            
            <span className="text-sm text-gray-500 sm:text-center">
                All password are stored in encrypted format.
            </span>
            
        </footer>
    )
}

export default Footer;