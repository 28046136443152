import { useEffect, useState } from "react";
import { APP_HOME } from "../constants/appNavigations";
import HeroImage from "../assets/images/hero.webp";
import userServices from "../services/userServices";
import googleLogin from "../libs/googleLogin";
import { FcGoogle } from "react-icons/fc";


export default function Home() {
    const [user, setUser] = useState("");

    useEffect(() => {
        setUser(userServices.getUser());
    }, []);

    return (
        <>
            <div className="flex justify-center">
                <img className="w-72" src={HeroImage} alt="PassGuard"></img>
            </div>
            <div className="text-center">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span className="block text-primary-900 xl:inline">Password manager </span>{" for your "}<br />
                    <span className="block xl:inline">
                        personal & business use
                    </span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl ">
                    Password managers provide safe, sharable credential storage. You can share credentials while maintaining security.
                </p>

                {user ? (
                    <div className="mt-5 sm:mt-8 sm:flex justify-center ">
                        <div className="rounded-md shadow">
                            <a
                                href={APP_HOME}
                                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 md:py-4 md:text-lg md:px-10"
                            >
                                Go to Dashboard
                            </a>
                        </div>
                    </div>
                ) : (
                    <div className="mt-5 sm:mt-8 sm:flex justify-center ">
                        <div className="mt-3 sm:mt-0 sm:ml-3">
                            <div
                                onClick={googleLogin}
                                className="w-full flex items-center justify-center px-14 py-3 border border-transparent text-base font-medium rounded-md text-priamry-700 bg-primary-200 cursor-pointer hover:bg-primary-200 md:py-4 md:text-lg md:px-10"
                            >
                                <FcGoogle size={25} className="mx-2" /> Login with Google
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </>
    );
}
