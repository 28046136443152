import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { app } from "../libs/firebase";
import userServices from "../services/userServices";

const provider = new GoogleAuthProvider();
const auth = getAuth(app);

const googleLogin = async () => {
    signInWithPopup(auth, provider)
        .then((result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            const user = result.user;
            const userdetails = {
                'token': token,
                'name': user.displayName,
                'email': user.email,
                'photo': user.photoURL,
            }
            userServices.setUser(userdetails);
            window.location.href = "/app/dashboard"
        }).catch((error) => {
            console.log("Error logging in!")
        });
}
export default googleLogin;