import React, { useState, useContext } from "react";
import { CopyToClipboard } from "../Commons";
import { FiExternalLink, FiEdit, FiTrash, FiLink } from "react-icons/fi";
import { IoEarthOutline } from "react-icons/io5";
import { PasswordView } from ".";
import { AppContext } from "../../contexts";
import { SharePassword } from "./";
import ReactGA from "react-ga4";
import * as Utils from "../../utils";

function PasswordRow(props) {
  const [sharePasswordId, setSharePasswordId] = useState("");
  const { groups } = useContext(AppContext);

  const handleDelete = () => {
    props.deleteKeyFn(props.id);
    ReactGA.event({
      category: "feature",
      action: "Delete password",
    });
  };

  const handleEdit = () => {
    props.editKeyFn(props);
    ReactGA.event({
      category: "feature",
      action: "Edit password",
    });
  };

  const getGroupNameById = (id) => {
    const group = groups.filter(group => group.id === id);
    return group.length > 0 ? group[0].name : "-";
  }


  const getFaviconLink = () => {
    let domain;
    if (props.website) {
      const url = Utils.getFullWebsiteLink(props.website);
      domain = Utils.getHostFromURL(url);
    }
    return `https://icons.duckduckgo.com/ip3/${domain}.ico`
  }

  return (
    <>
      <tr className="bg-white border-b">
        <th
          scope="row"
          className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap"
        >
          <div className="flex flex-row items-center">
            <div className="mr-2 w-4">
              {
                props.website ? <img
                  height="16"
                  width="16"
                  src={getFaviconLink()}
                /> : <IoEarthOutline />
              }
            </div>
            <span className="w-60 truncate ..">
              {props.name}
            </span>
            {
              props.website && <span className="px-2">
                <a href={Utils.getFullWebsiteLink(props.website)} target="_blank" rel="noreferrer" className="text-blue-500">
                  <FiExternalLink />
                </a>
              </span>
            }
          </div>

        </th>
        <td className="flex p-2">
          {props.username ? (
            <>
              <CopyToClipboard textToCopy={props.username} size={18}></CopyToClipboard>
              <span className="w-60 truncate ...">{props.username}</span>
            </>
          ) : <span className="px-2">-</span>
          }
        </td>
        <td className="p-2">
          <PasswordView password={props.password} />
        </td>
        <td className="p-2">
          {getGroupNameById(props.group)}
        </td>

        <td className="p-2 space-x-4">
          <button
            onClick={handleEdit}
            className="font-medium text-blue-600 cursor-pointer"
          >
            <FiEdit size={16} />
          </button>
          <button
            onClick={handleDelete}
            className="font-medium text-red-600 cursor-pointer"
          >
            <FiTrash size={16} />
          </button>
          <button
            onClick={() => setSharePasswordId(props.id)}
            className="font-medium text-black cursor-pointer"
          >
            <FiLink size={16} />
            <SharePassword
              show={props.id === sharePasswordId}
              passwordId={props.id}
              handleCancel={() => setSharePasswordId(null)}
            />
          </button>
        </td>
      </tr>
    </>
  );
}

export default PasswordRow;
