import React from "react";

export default function Content({ title, children }) {
    return (
        <div className="pt-16">
            <main className="min-h-screen">
                <div className="mx-auto py-6 sm:px-6 lg:px-8">
                    <div className="px-4 py-6 sm:px-0">
                        {children ? (
                            children
                        ) : (
                            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96" />
                        )}
                    </div>
                </div>
            </main>
        </div>
    );
}
