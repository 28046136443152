const Repeater = ({ children, count = 0 }) => {
    return (
        <>
            {
                Array(count).fill(0).map(key => {
                    return children;
                })
            }
        </>
    )
}
export default Repeater;