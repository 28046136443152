import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom'
import ReactGA from "react-ga4";

try {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  ReactGA.send('pageview');
} catch (error) {
  console.log('Analytics disabled!')
}


const root = ReactDOM.createRoot(document.getElementById('root'));
document.body.classList.add('h-full')
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);
