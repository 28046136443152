import React, { useState } from "react"
import { CopyToClipboard } from "../Commons"
import { IoIosEye, IoIosEyeOff } from "react-icons/io"

export default function PasswordView(props) {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className="flex flex-row">
            {
                props.password &&
                <CopyToClipboard textToCopy={props.password} size={18}></CopyToClipboard>
            }
            {
                props.password === '' ?
                    <span className="px-2">-</span> : null
            }
            {/* {
                showPassword &&
                <>
                    {props.password}
                    <IoIosEyeOff
                        size={20}
                        className="cursor-pointer ml-2"
                        onClick={() => setShowPassword(false)}
                    />
                </>
            } */}
            {
                !showPassword && props.password &&
                <>
                    {'*'.repeat(10)}
                    {/* <IoIosEye
                        size={20}
                        className="cursor-pointer ml-2"
                        onClick={() => {
                            setShowPassword(true);
                            setTimeout(() => {
                                setShowPassword(false);
                            }, 5000);
                        }}
                    /> */}
                </>
            }
        </div>
    )
}
