import { useState, useContext } from "react";
import { ConfirmPopup, Repeater } from "../Commons";
import { EditGroupModal, CreateGroupModal, ShareGroupModal } from '.';
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FiEdit, FiTrash } from "react-icons/fi"
import { FaUserPlus } from "react-icons/fa"
import { AppContext } from "../../contexts";
import groupService from "../../services/groupService";
import userServices from "../../services/userServices";
import ReactGA from "react-ga4";


export default function GroupList() {
  const [showCreate, setShowCreate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showShareGroup, setShowShareGroup] = useState();
  const [deleteKeyId, setDeleteKeyId] = useState("");
  const [editGroup, setEditGroup] = useState("");
  const {
    groups,
    isGroupLoading,
    selectedFilterGroup,
    setSelectedFilterGroup,
  } = useContext(AppContext);

  const openDeleteModal = (id) => {
    setDeleteKeyId(id);
    setShowDelete(true);
    ReactGA.event({
      category: "feature",
      action: "Delete group",
    });
  };

  const deleteConfirmationHandler = async (response) => {
    if (response) {
      await groupService.deleteGroup(deleteKeyId);
    }
    setDeleteKeyId("");
    setShowDelete(false);
  };

  const openEditModal = (data) => {
    setEditGroup(data);
    setShowEdit(true);
    ReactGA.event({
      category: "feature",
      action: "Edit group",
    });
  };

  const editModalHandler = async (data) => {
    setShowEdit(false);
    setEditGroup("");
  };

  const openCreateKeyModal = () => {
    setShowCreate(true);
    ReactGA.event({
      category: "feature",
      action: "Create group",
    });
  };

  const groupItem = (group, isSelected) => {
    return (
      <li
        className={`hover:bg-gray-100 ${isSelected ? "bg-gray-100" : null}`}
        key={group.id}
      >
        <div className="flex p-2 text-sm text-gray-600 rounded-lg justify-between">
          <div
            onClick={() => setSelectedFilterGroup(group.id)}
            className="w-44 cursor-pointer truncate ..."
          >{group.name}</div>
          <div className="grid grid-cols-3 gap-3">
            <button
              onClick={() => setShowShareGroup(group.id)}
              className="font-medium text-gray-600 cursor-pointer"
            >
              <FaUserPlus size={19} />
            </button>
            <button
              onClick={() => openEditModal(group)}
              className="font-medium text-blue-600 cursor-pointer"
            >
              <FiEdit />
            </button>
            <button
              onClick={() => openDeleteModal(group.id)}
              className="font-medium text-red-600 cursor-pointer"
            >
              <FiTrash />
            </button>
          </div>
          <ShareGroupModal
            show={showShareGroup === group.id}
            group={group}
            handleCancel={() => setShowShareGroup(null)}
          />
        </div>
      </li>
    )
  }

  const showGroups = () => {
    const sharedGroups = [];
    const ownGroups = [];

    groups.map((group) => {
      const isGroupSelected = group.id === selectedFilterGroup;
      const user = userServices.getUser()
      const isGroupShared = group.createdByEmail != user['email']
      isGroupShared
        ? sharedGroups.push(groupItem(group, isGroupSelected))
        : ownGroups.push(groupItem(group, isGroupSelected))
    })

    // Add divider between own groups and shared groups
    if (sharedGroups.length > 0 && ownGroups.length > 0) {
      sharedGroups.unshift(
        <div className="border-t p-2 mt-1">
          <span className="text-sm font-medium text-gray-900">Shared by others</span>
        </div>
      )
    }

    return ownGroups.concat(sharedGroups)
  }
  return (
    <div>
      <div className="shadow-md sm:rounded-lg bg-white">
        <aside className="w-full" aria-label="Sidebar">
          <div className="overflow-y-auto rounded ">
            <h3 className="font-medium p-4 text-gray-900 bg-gray-100">
              Groups
              <p className="text-xs font-normal">Manage passwords by team, client, etc.</p>
            </h3>
            {
              isGroupLoading && (
                <>
                  <ul className="p-2">
                    <Repeater count={5}>
                      <li>
                        <div className="flex items-center p-2 text-sm text-gray-900 rounded-lg">
                          <div className="h-3 animate-pulse rounded bg-gray-200 w-full "></div>
                        </div>
                      </li>
                    </Repeater>
                  </ul>
                </>
              )
            }
            <ul className="p-2">
              {
                !isGroupLoading && showGroups()
              }
              {
                !isGroupLoading && groups.length === 0 && <li>
                  <div className="flex p-2 text-sm text-gray-900 rounded-lg justify-between">
                    <div className="w-44">No groups</div>
                  </div>
                </li>

              }
              {
                !isGroupLoading && <li className="cursor-pointer mt-2" onClick={openCreateKeyModal}>
                  <div className="flex flex-row items-center p-2 text-sm text-gray-900 rounded-lg bg-gray-100">
                    <AiOutlinePlusCircle /><span>&nbsp;Add group</span>
                  </div>
                </li>
              }
            </ul>
          </div>
        </aside>
      </div>
      <div>
        <ConfirmPopup
          show={showDelete}
          confirmHandler={deleteConfirmationHandler}
          title={"Confirm Delete"}
          body={
            "Deleting a group will delete all passwords within the group. This action is irreversible."
          }
        />
        <CreateGroupModal
          show={showCreate}
          createHandler={() => setShowCreate(false)}
        />
        {
          showEdit && <EditGroupModal
            data={editGroup}
            show={showEdit}
            editHandler={editModalHandler}
          />
        }
      </div>
    </div>
  );
}
