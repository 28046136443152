function getUser() {
    let user = localStorage.getItem("user");
    return JSON.parse(user);
  }
  
  function checkRememberMe() {
    let user = localStorage.getItem("rememberMeInfo");
    return JSON.parse(user);
  }
  
  function setRememberMe(params) {
    localStorage.setItem("rememberMeInfo", JSON.stringify(params));
  }
  
  function clearRememberMe() {
    localStorage.removeItem("rememberMeInfo");
  }
  
  function setUser(params) {
    localStorage.setItem("user", JSON.stringify(params));
  }
  
  function logoutUser() {
    localStorage.removeItem("user");
  }
  
  const userServices = {
    getUser,
    checkRememberMe,
    setRememberMe,
    clearRememberMe,
    setUser,
    logoutUser,
  };
  export default userServices;
  