// Duration must be in minutes
const LINK_EXPIRY_DURATION = [
    {
        duration: 60,
        title: '1 hour',
    },
    {
        duration: 60 * 24,
        title: '1 day',
    },
    {
        duration: 60 * 24 * 7,
        title: '7 days',
    },
    {
        duration: 60 * 24 * 15,
        title: '15 days',
    },
    {
        duration: 60 * 24 * 30,
        title: '30 days',
    },
]

export default LINK_EXPIRY_DURATION;