import { createContext, useEffect, useState } from "react";
import { onSnapshot, query, where } from "firebase/firestore";
import groupService from "../services/groupService"
import passwordService from "../services/passwordService";
import userServices from "../services/userServices";
export const AppContext = createContext({});

const AppContextProvider = (props) => {
    const [groups, setGroups] = useState([]);
    const [passwords, setPasswords] = useState([]);
    const [selectedFilterGroup, setSelectedFilterGroup] = useState("");
    const [isGroupLoading, setIsGroupLoading] = useState(false);
    const [isPasswordLoading, setIsPasswordLoading] = useState(false);
    const user = userServices.getUser();

    const values = {
        groups,
        setGroups,
        passwords,
        setPasswords,
        selectedFilterGroup,
        setSelectedFilterGroup,
        isGroupLoading,
        setIsGroupLoading,
        isPasswordLoading,
        setIsPasswordLoading
    }

    useEffect(() => {
        setIsPasswordLoading(true);
        let password_query = query(
            passwordService.passwordRef,
            where("owners", "array-contains", user.email)
        );

        if (Boolean(selectedFilterGroup)) {
            password_query = query(
                passwordService.passwordRef,
                where("owners", "array-contains", user.email),
                where("group", "==", selectedFilterGroup),
            );
        }

        const unsubscribePasswords = onSnapshot(password_query, snapshot => {
            const passwords = snapshot.docs.map(doc => {
                let data = doc.data();
                data['id'] = doc.id;
                return data;
            });
            setPasswords(passwords);
            setIsPasswordLoading(false);
        })
        return () => {
            unsubscribePasswords();
        }
    }, [selectedFilterGroup]);

    const selectDefaultOwnGroup = (groups) => {
        if (groups) {
            const ownGroups = groups.filter(group => {
                const user = userServices.getUser()
                return group.createdByEmail == user['email']
            });
            if (ownGroups) {
                setSelectedFilterGroup(ownGroups[0].id);
            } else {
                setSelectedFilterGroup(groups[0].id);
            }
        }
    }

    useEffect(() => {
        setIsGroupLoading(true);
        let group_query = query(
            groupService.groupRef,
            where("owners", "array-contains", user.email),
        );

        const unsubscribeGroups = onSnapshot(group_query, snapshot => {
            const data = snapshot.docs.map(doc => {
                let data = doc.data();
                data['id'] = doc.id;
                return data;
            })
            setGroups(data);
            setIsGroupLoading(false);
            selectDefaultOwnGroup(data);
        });
        return () => {
            unsubscribeGroups();
        }
    }, [])

    return (
        <AppContext.Provider value={values}>
            {props.children}
        </AppContext.Provider>
    )
}
export default AppContextProvider;
