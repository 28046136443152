import { useState, useContext } from "react";
import passwordService from '../../services/passwordService';
import { ConfirmPopup } from "../Commons";
import { EditPasswordModal, CreatePasswordModal, PasswordRow } from '.';
import { TableSkeleton, ButtonSkeleton } from "../Skeletons";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { SiMicrosoftexcel } from "react-icons/si";
import { CSVLink } from "react-csv";
import no_password from "../../assets/images/no_password.jpg";
import { AppContext } from "../../contexts";

export default function PasswordList() {
  const [showCreate, setShowCreate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [deleteKeyId, setDeletePasswordId] = useState("");
  const [editPassword, setEditPassword] = useState("");

  const {
    groups,
    passwords,
    isPasswordLoading,
    selectedFilterGroup,
    setSelectedFilterGroup,
  } = useContext(AppContext);

  const openDeleteModal = (key_id) => {
    setDeletePasswordId(key_id);
    setShowDelete(true);
  };

  const deleteConfirmationHandler = async (response) => {
    if (response) {
      await passwordService.deletePassword(deleteKeyId);
    }
    setDeletePasswordId("");
    setShowDelete(false);
  };

  const openEditPasswordModal = (data) => {
    passwordService.getPassword(data.id).then(data => {
      setEditPassword(data);
      setShowEdit(true);
    })
  };

  const editModalHandler = async (response) => {
    setShowEdit(false);
    setEditPassword("");
    setSelectedFilterGroup(null);
  };

  const createModalHandler = (response) => {
    setShowCreate(false);
    setSelectedFilterGroup(null);
  };

  const exportData = () => {
    return passwords.map(password => {
      return {
        'name': password.name,
        'username': password.username,
        'password': password.password,
        'website': password.website,
        'note': password.note,
      }
    })
  }

  const passwordActions = () => (
    <div className="flex flex-row justify-between text-right mb-2">
      <div>
        <select
          id="group"
          name="group"
          value={selectedFilterGroup}
          onChange={(e) => setSelectedFilterGroup(e.target.value)}
          disabled={true}
          className="appearance-none block w-full text-sm placeholder:text-gray-400 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none"
          placeholder="Select group"
        >
          {groups.map(group => {
            return <option value={group.id} key={group.id}>
              Group - {group.name}
            </option>;
          })}
        </select>
      </div>
      <div>
        {passwords.length > 0 && (
          <div className="flex justify-end gap-4">
            <div
              className="bg-green-500 hover:bg-green-700 py-2 px-4 text-white rounded text-sm w-24"
            >
              <CSVLink
                data={exportData()}
                target="_blank"
                filename={`PassGuard passwords export.csv`}
              >
                <div className="flex align-middle items-center">
                  <SiMicrosoftexcel className="mr-2" />  Export
                </div>
              </CSVLink>
            </div>

            <button
              onClick={() => setShowCreate(true)}
              className="bg-primary-800 hover:bg-primary-900 text-white py-2 px-4 rounded text-sm"
              data-modal-toggle="authentication-modal"
            >
              <div className="flex justify-between align-middle items-center">
                <AiOutlinePlusCircle className="mr-1" />Add password
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );

  const passwordActionsSkeleton = () => (
    <div className="flex flex-row justify-end text-right mb-2">
      <div className="flex gap-4">
        <ButtonSkeleton />
        <ButtonSkeleton />
      </div>
    </div>
  );

  return (
    <div>
      {
        isPasswordLoading
          ? passwordActionsSkeleton()
          : <>{ groups.length > 0 && passwordActions()}</>
      }
      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
          <TableSkeleton rowCount={10} colCount={5} show={isPasswordLoading} />
          <table className={`${isPasswordLoading ? 'hidden' : ''} w-full text-sm text-left text-gray-500 `}>
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 ">
              <tr>
                <th scope="col" className="px-6 py-4">Name</th>
                <th scope="col" className="px-2 py-4">Username</th>
                <th scope="col" className="px-2 py-4">Password</th>
                <th scope="col" className="px-4 py-4">Group</th>
                <th scope="col" className="px-2 py-4 w-[120px]">Actions</th>
              </tr>
            </thead>
            <tbody className={`${isPasswordLoading ? 'hidden' : ''}`}>
              {
                passwords.map((password) => (
                  <PasswordRow
                    id={password.id}
                    key={password.id}
                    name={password.name}
                    website={password.website}
                    username={password.username}
                    password={password.password}
                    group={password.group}
                    modifiedAt={password.modifiedAt}
                    modifiedByName={password.modifiedByName}
                    deleteKeyFn={openDeleteModal}
                    editKeyFn={openEditPasswordModal}
                  />
                ))
              }
              {passwords.length === 0 && (
                <tr className="bg-white border-b">
                  <td className="px-6 py-4" colSpan={5}>
                    <div className="flex flex-col justify-center items-center h-96 gap-y-2">
                      <img src={no_password} width={350} />
                      <h4 className="font-semibold text-xl">No Passwords</h4>
                      <p> Start using PassGuard by creating your first password.</p>
                      {
                        groups.length > 0
                          ? <button
                            onClick={() => setShowCreate(true)}
                            className="bg-primary-800 hover:bg-primary-900 text-white py-2 px-4 rounded"
                            data-modal-toggle="authentication-modal"
                          >
                            Add password
                          </button>
                          : (
                            <div className="bg-slate-100 p-4 rounded-lg w-[400px] text-center">
                              <span className="text-sm">
                                First, <span className="font-semibold">create group</span> for your password.
                                That is to keep passwords organised and easy sharing.
                              </span>
                            </div>
                          )
                      }
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

        </div>
      </div>
      <div>
        <ConfirmPopup
          show={showDelete}
          confirmHandler={deleteConfirmationHandler}
          title={"Confirm Delete"}
          body={
            "Are you sure you want to delete password? This action cannot be undone."
          }
        />
        {
          showCreate &&
          <CreatePasswordModal
            show={showCreate}
            createHandler={createModalHandler}
          />
        }
        {
          showEdit &&
          <EditPasswordModal
            data={editPassword}
            show={showEdit}
            editHandler={editModalHandler}
          />
        }
      </div>
    </div >
  );
}
