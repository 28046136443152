const navigation = [
    {
      name: "Documentation",
      href: "documentation",
      newPage: false,
      show: false,
    },
    {
      name: "Pricing",
      href: "pricing",
      newPage: false,
      show: false,
    },
    {
      name: "Service Status",
      href: "https://uptime.passguard.in",
      newPage: true,
      show: true,
    },
  ];
  
  export default navigation;
  