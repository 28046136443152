import { db } from "../libs/firebase";
import {
    collection,
    doc,
    addDoc,
    getDoc,
    updateDoc,
    deleteDoc,
} from "firebase/firestore";
import * as utils from "../utils";
import userServices from "./userServices";
import passwordService from "./passwordService";
import { GROUPS_COLLECTION } from "./collections";
import * as _ from "lodash";

const groupRef = collection(db, GROUPS_COLLECTION);

async function createGroup(data) {
    const user = userServices.getUser();
    data['createdByEmail'] = user.email;
    data['createdByName'] = user.name;
    data['createdAt'] = utils.getCurrentTimestamp();

    data['modifiedByEmail'] = user.email;
    data['modifiedByName'] = user.name;
    data['modifiedAt'] = utils.getCurrentTimestamp();
    data['owners'] = [user.email];
    const response = await addDoc(groupRef, data);
    return response;
}

// async function listGroups() {
//     const user = userServices.getUser();
//     let group_query = query(
//         groupRef,
//         where("owners", "array-contains", user.email),
//     );
//     const querySnapshot = await getDocs(group_query);
//     const groups = querySnapshot.docs.map(doc => {
//         let data = doc.data();
//         data['id'] = doc.id;
//         return data;
//     });
//     return groups;
// }

async function updateGroup(id, data) {
    const docRef = doc(db, GROUPS_COLLECTION, id);
    await updateDoc(docRef, data);
    return await getGroup(id);
}

async function deleteGroup(id) {
    const docRef = doc(db, GROUPS_COLLECTION, id);
    await deleteDoc(docRef);
    await passwordService.removePasswordsWithGroup(id);
}

async function getGroup(id) {
    const docRef = doc(db, GROUPS_COLLECTION, id);
    let group = await getDoc(docRef);
    let data = group.data();
    data['id'] = group.id;
    return data;
}

async function removeGroupOwner(id, email){
    const docRef = doc(db, GROUPS_COLLECTION, id);
    let group = await getDoc(docRef);
    let data = group.data();

    // Remove owner from list
    const removeIndex = data.owners.indexOf(email)
    data.owners.splice(removeIndex, 1)
    await updateDoc(docRef, data);
    await passwordService.removeOwnerFromPasswordsWithGroup(group.id, email);
    return await getGroup(id);
}

async function addGroupOwner(id, email) {
    const docRef = doc(db, GROUPS_COLLECTION, id);
    let group = await getDoc(docRef);
    let data = group.data();

    // Add owner to list
    data.owners.push(email);
    data.owners = _.uniq(data.owners);
    await updateDoc(docRef, data);
    await passwordService.syncPasswordOwnersWithGroup(group.id, data.owners);
    return await getGroup(id);
}


export default {
    createGroup,
    // listGroups,
    updateGroup,
    deleteGroup,
    getGroup,
    addGroupOwner,
    removeGroupOwner,
    groupRef,
};