import { useNavigate } from "react-router-dom";
import userServices from "../../services/userServices";
import Countdown from 'react-countdown';
import ReactGA from "react-ga4";

const UserBadge = (props) => {
    const navigate = useNavigate();

    const handleSignout = () => {
        ReactGA.event({
            category: "feature",
            action: "Auto sign out",
        });
        userServices.logoutUser();
        navigate("/");
    }

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            handleSignout()
        } else {
            return (
                <span>
                    Session ends in {minutes} mins {seconds} secs
                </span>
            );
        }
    }

    return (
        <>
            <div className="flex flex-row space-x-4">
                <div className="">
                    <img
                        src={props.photo}
                        alt="User profile"
                        className="bg-white w-10 rounded-full"
                    ></img>
                </div>
                <div className="text-sm leading-none text-white text-left">
                    <p >{props.name}</p>
                    <p>{props.email}</p>
                    <p className="text-xs text-orange-500">
                        <Countdown
                            date={Date.now() + (1000 * 60 * process.env.REACT_APP_SESSION_TIMEOUT_MINUTES)}
                            renderer={renderer}
                        />
                    </p>
                </div>
            </div>
        </>
    )
}
export default UserBadge;