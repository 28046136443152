import { Outlet, useLocation } from "react-router-dom";
import { getContentTitle } from "../../utils";
import { Content, Navbar, Footer } from "../Commons";
import { ProtectedRoute } from '../';

function AppLayout() {
  const pathname = useLocation().pathname;
  return (
    <ProtectedRoute>
        <Navbar/>
        <Content title={getContentTitle(pathname)}>
          <Outlet />
        </Content>
      <Footer />
    </ProtectedRoute>
  );
}

export default AppLayout;
