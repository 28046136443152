import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { FiExternalLink } from "react-icons/fi";
import { IoEarthOutline } from "react-icons/io5";
import { GrUnlink } from "react-icons/gr";
import { CopyToClipboard, LoadingSpinner } from "../../components/Commons";
import passwordService from "../../services/passwordService";
import logo from "../../assets/branding/logo-short.png";
import * as utils from "../../utils";

const SharePassword = () => {
    const params = useParams();
    const [password, setPassword] = useState("");
    const [passwordLink, setPasswordLink] = useState("");
    const [showPassword, setShowPssword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);

    const getWebsiteLink = () => {
        if (password && password.website) {
            if (password.website.startsWith('http://') || password.website.startsWith('https://')) {
                return password.website;
            } else {
                return `http://${password.website}`;
            }
        }
    }

    const getFaviconLink = () => {
        let domain;
        if (password && password.website) {
            if (password.website.startsWith('http://') || password.website.startsWith('https://')) {
                domain = password.website.split("//")[1];
                domain = domain.split("/")[0];
            } else {
                domain = password.website
            }
        }
        return `https://icons.duckduckgo.com/ip3/${domain}.ico`
    }

    const checkIfLinkIsValid = (passwordLink) => {
        const currentTime = utils.getCurrentTimestamp();
        if (passwordLink.oneTimeView && passwordLink.viewed)
            return false
        if (new Date(currentTime) > new Date(passwordLink.expiresOn))
            return false;
        return true;
    }

    const passwordExpiry = () => {
        if (passwordLink) {
            return <div className="bg-green-100 text-green-800 text-center text-sm px-3 py-2 w-full">
                {
                    passwordLink.oneTimeView
                        ? `This is one time view password. This link won't work next time.`
                        : `You can view this password until ${utils.dateFormatter(passwordLink.expiresOn)}`
                }
            </div>
        }
    }

    const passwordTitle = () => {
        return (
            <div className="flex flex-row justify-center mb-5">
                <div className="mr-2">
                    {
                        password.website ? <img
                            height="25"
                            width="25"
                            alt="Website"
                            src={getFaviconLink()}
                        /> : <IoEarthOutline size={25} />
                    }
                </div>
                <span className="text-xl font-semibold">{password.name}</span>
                {
                    password.website && <span className="px-2">
                        <a href={getWebsiteLink()} target="_blank" rel="noreferrer" className="text-blue-500">
                            <FiExternalLink />
                        </a>
                    </span>
                }
            </div>
        )
    }

    const usernameField = () => {
        return (
            password.username && <div className="my-4">
                <label htmlFor="username" className="block mb-2 text-sm text-gray-600 " > Username</label>
                <div className="flex">
                    <input
                        type="text"
                        name="username"
                        id="username"
                        value={password.username}
                        className="bg-gray-50 text-gray-500 text-sm rounded-md block w-full p-2.5"
                        disabled
                    />
                    <CopyToClipboard textToCopy={password.username} />
                </div>
            </div>
        )
    }

    const passwordField = () => {
        return (
            password.password && <div className="mb-4">
                <label htmlFor="password" className="block mb-2 text-sm text-gray-600 ">Password</label>
                <div className="flex">
                    <div className="relative w-full">
                        <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            id="password"
                            value={password.password}
                            className="bg-gray-50 text-gray-500 text-sm rounded-md block w-full p-2.5"
                            disabled
                        />
                        <button
                            type="button"
                            className="absolute top-0 right-0 p-2.5 bg-gray-50 rounded-md"
                            onClick={() => setShowPssword(!showPassword)}
                        >
                            {showPassword ? <AiFillEyeInvisible size={25} color="gray" /> : <AiFillEye size={25} color="gray" />}
                        </button>
                    </div>
                    <CopyToClipboard textToCopy={password.password} />
                </div>
            </div>
        )
    }

    const noteField = () => {
        return (
            password.note && <div className="mb-4">
                <label htmlFor="note" className="block mb-2 text-sm text-gray-600 ">Note</label>
                <p className="text-gray-500 text-sm">{password.note}</p>
            </div>
        )
    }

    const websiteField = () => {
        const websiteLink = getWebsiteLink();
        return (
            websiteLink &&
            <div className="mb-4">
                <label htmlFor="note" className="block mb-2 text-sm text-gray-600 " >Website</label>
                <a href={websiteLink} target="_blank" rel="noreferrer nofollow">
                    <p className="text-blue-500 text-sm">{websiteLink}</p>
                </a>
            </div>
        )
    }

    const mainContent = () => {
        return (
            <>
                {
                    isLoading &&
                    <>
                        <LoadingSpinner height="50" width="50" />
                        <p className="block my-4 text-sm text-gray-600">Loading secure password...</p>
                    </>
                }
                {
                    !isLoading && isPasswordValid &&
                    <>
                        <div className="w-full p-6">
                            {passwordTitle()}
                            {usernameField()}
                            {passwordField()}
                            {noteField()}
                            {websiteField()}
                        </div>
                        {passwordExpiry()}
                    </>
                }
                {
                    !isLoading && !isPasswordValid &&
                    <>
                        <GrUnlink size={50} color={'red'} />
                        <p className="block my-4 text-sm text-gray-600">Opps! The shared link has expired, please request a new one.</p>
                    </>
                }

            </>
        )
    }

    useEffect(() => {
        setIsLoading(true);
        passwordService.getPasswordLink(params.linkId).then(passwordLink => {
            const isLinkValid = checkIfLinkIsValid(passwordLink);
            if (isLinkValid) {
                setPasswordLink(passwordLink);
                passwordService.getPassword(passwordLink.password).then(password => {
                    setPassword(password);
                    setIsPasswordValid(true);
                    setIsLoading(false);
                });
            } else {
                setIsPasswordValid(false);
                setIsLoading(false);
                passwordService.deletePasswordLink(passwordLink.id);
            }
        }).catch(err => {
            setIsPasswordValid(false);
            setIsLoading(false);
        });
    }, [])

    return (
        <div className="w-full min-h-screen  overflow-y-scroll flex flex-col align-middle items-center justify-center bg-gradient-to-b from-orange-200 to-orange-400 py-12">
            <div className="w-full max-w-md">
                <div className="flex flex-col items-center">
                    <img src={logo} alt="PassGuard" width="70" />
                    <p className="text-2xl font-medium text-center py-4">
                        Someone on PassGuard has <br />shared this password with you.
                    </p>
                </div>
                <div className="bg-white w-full rounded-lg border border-gray-200 shadow-md">
                    <div className="min-h-[200px] w-full flex flex-col align-middle items-center justify-center">
                        {mainContent()}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SharePassword;