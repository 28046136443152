import React, { Fragment, useState, useEffect } from "react"
import { useFormik } from "formik";
import { Dialog, Transition } from "@headlessui/react";
import { CopyToClipboard, LoadingSpinner } from "../Commons";
import { BsChevronDown } from "react-icons/bs";
import { BiChevronRight, BiChevronDown, BiTrash } from "react-icons/bi";
import * as utils from "../../utils";
import { onSnapshot, query, where } from "firebase/firestore";
import passwordService from "../../services/passwordService";
import * as constants from "../../constants";
import * as Yup from "yup";


const SharePassword = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [passwordLink, setPasswordLink] = useState("");
    const [passwordLinkExpiresOn, SetPasswordLinkExpiresOn] = useState('');
    const [passwordCopied, setPasswordCopied] = useState(false);
    const [showSharingHistory, setShowSharingHistory] = useState(false);
    const [sharedLinks, setSharedLinks] = useState([]);

    const formik = useFormik({
        initialValues: {
            duration: "",
            oneTimeView: false,
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            duration: Yup.number().required('Duration is required'),
            oneTimeView: Yup.boolean().required(),
        }),
        onSubmit: async (values) => {
            setIsLoading(true)
            passwordService.generatePasswordLink(
                props.passwordId,
                values['duration'],
                values['oneTimeView'],
            ).then(data => {
                setPasswordLink(data.link);
                SetPasswordLinkExpiresOn(data.expiresOn);
                setIsLoading(false);
            })
        }
    });

    const handleCancel = () => {
        formik.resetForm();
        setPasswordLink(null);
        setIsLoading(false);
        props.handleCancel(null);
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(passwordLink).then(
            () => {
                setPasswordCopied(true);
                setTimeout(() => {
                    setPasswordCopied(false);
                }, 1000);
            },
            (err) => {
                console.log("failed to copy", err.message);
            }
        );
    }

    const expireLink = (passwordLinkId) => {
        passwordService.deletePasswordLink(passwordLinkId);
    }

    useEffect(() => {
        const password_query = query(
            passwordService.passwordLinksRef,
            where("password", "==", props.passwordId),
        );
        const unsubscribePasswordLinks = onSnapshot(password_query, snapshot => {
            const _sharedLinks = snapshot.docs.map(doc => {
                let data = doc.data();
                data['id'] = doc.id;
                return data;
            });
            setSharedLinks(_sharedLinks)
        })
        return () => {
            unsubscribePasswordLinks();
        }
    }, [])

    const sharingHistoryRow = (link, idx) => {
        const isExpired = new Date(utils.getCurrentTimestamp()) > new Date(link.expiresOn);
        return (
            <tr className="bg-white border-b" key={idx}>
                <td className="p-2">
                    {utils.dateFormatter(link.expiresOn)}
                    {isExpired && <span className="text-xs text-red-400"> (expired)</span>}
                </td>
                <td className="flex p-2 justify-end">
                    {
                        !isExpired && <CopyToClipboard
                            textToCopy={utils.getFullPasswordSharingLink(link.id)}
                            size={15} />
                    }
                    <BiTrash
                        size={15}
                        color="red"
                        title="Expire"
                        onClick={() => expireLink(link.id)}
                        className="cursor-pointer" />
                </td>
            </tr>
        )
    }

    const sharedLinkList = () => {
        return (
            sharedLinks.length > 0 && !isLoading &&
            <div className="bg-white text-gray-900">
                <div>
                    <button
                        type="button"
                        onClick={() => setShowSharingHistory(!showSharingHistory)}
                        className="flex w-full text-sm text-left text-gray-900 pb-2"
                    >
                        {
                            showSharingHistory
                                ? <BiChevronDown size={20} />
                                : <BiChevronRight size={20} />
                        }
                        <span>Sharing history</span>
                    </button>
                    {
                        showSharingHistory &&
                        <div className="overflow-x-auto relative">
                            <table className="w-full text-sm text-left text-gray-500">
                                <thead className="text-sm text-gray-700 bg-gray-50 ">
                                    <tr>
                                        <td className="p-2 font-semibold">
                                            Valid until
                                        </td>
                                        <td className="p-2 font-semibold text-right">
                                            Actions
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sharedLinks.map((link, idx) => sharingHistoryRow(link, idx))
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        )
    }

    const sharePasswordForm = () => {
        return (
            <div>
                <div id="password-duration">
                    <div className="inline-block relative w-full">
                        <select
                            name="duration"
                            id="duration"
                            value={formik.values.duration}
                            onChange={formik.handleChange}
                            className="block appearance-none w-full text-sm bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded leading-tight"
                        >
                            <option value="">Select duration</option>
                            {
                                constants.LINK_EXPIRY_DURATION.map((duration, idx) => {
                                    return <option value={duration.duration} key={idx}>
                                        {duration.title}
                                    </option>
                                })
                            }
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <BsChevronDown />
                        </div>
                    </div>
                    <p className="text-red-500 text-xs mt-1 mb-2">
                        {formik.errors.duration ? formik.errors.duration : ""}
                    </p>
                </div>
                <div id="oneTimeView">
                    <div className="flex items-center">
                        <input
                            id="oneTimeView"
                            type="checkbox"
                            value={formik.values.oneTimeView}
                            name="oneTimeView"
                            onChange={formik.handleChange}
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 " />
                        <label htmlFor="oneTimeView" className="py-2 ml-2 w-full text-sm text-gray-900 dark:text-gray-300">
                            One time view only
                        </label>
                    </div>
                    <p className="text-red-500 text-xs mt-1 mb-2">
                        {formik.errors.oneTimeView ? formik.errors.oneTimeView : ""}
                    </p>
                </div>
                <div>
                    {sharedLinkList()}
                </div>
            </div>
        )
    }

    const mainContent = () => {
        return (
            <form onSubmit={formik.handleSubmit}>
                <div className="bg-white mx-6 my-4">
                    <div className="sm:flex sm:items-start">
                        <div className="w-full text-left mt-2">
                            <Dialog.Title
                                as="h3"
                                className="text-lg leading-6 font-medium text-gray-900"
                            >
                                Password Sharing Link
                                <p
                                    className="text-sm font-normal mt-1 text-gray-500">
                                    Generate unique link to share password securely
                                </p>
                            </Dialog.Title>
                            <div className="mt-2 w-full">
                                <div className="flex items-baseline w-full">
                                    <div className="w-full mt-2">
                                        {
                                            !passwordLink && (
                                                sharePasswordForm()
                                            )
                                        }
                                        {
                                            passwordLink && (
                                                <>
                                                    <input
                                                        type="text"
                                                        id="disabled-input"
                                                        aria-label="disabled input"
                                                        className="my-3 bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-md p-2.5 block w-full"
                                                        value={passwordLink}
                                                        disabled
                                                    />
                                                    <p className="text-sm font-normal mt-1 text-gray-500">
                                                        Expires on {utils.dateFormatter(passwordLinkExpiresOn)}
                                                    </p>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 p-4 flex justify-between">
                    <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-5sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={handleCancel}
                    >
                        {passwordLink ? "Done" : "Cancel"}
                    </button>

                    {passwordLink ?
                        <button
                            type="button"
                            onClick={copyToClipboard}
                            className="bg-primary-800 hover:bg-primary-900 text-white py-2 px-4 rounded text-sm"
                        >
                            {passwordCopied ? "Copied!" : "Copy Link"}

                        </button>
                        :
                        <button
                            type="submit"
                            className="bg-primary-800 hover:bg-primary-900 text-white py-2 px-4 rounded text-sm"
                        >
                            <div className="flex justify-between align-middle items-center">
                                {isLoading ? <LoadingSpinner /> : "Generate Link"}
                            </div>
                        </button>
                    }
                </div>
            </form>
        )

    }
    return (
        <Transition.Root show={props.show} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => null}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 max-w-lg">
                                {mainContent()}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default SharePassword;