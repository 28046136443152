import * as Yup from "yup";
import { Fragment, useState, useEffect, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { LoadingSpinner } from "../Commons";
import { useFormik } from "formik";
import { AppContext } from "../../contexts";
import passwordService from "../../services/passwordService";

export default function EditPasswordModal(props) {
  const [loading, setLoading] = useState(false);
  const [initalValues, setInitialValues] = useState({
    name: "",
    username: "",
    password: "",
    website: "",
    note: "",
    group: "",
    owners: [],
  });
  const { groups } = useContext(AppContext);

  const formik = useFormik({
    initialValues: initalValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      username: Yup.string().required('Username is required'),
      password: Yup.string(),
      website: Yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-_%]+&?)?$/,
        'Enter correct website'
      ),
      note: Yup.string(),
      group: Yup.string().required('Group is required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      await passwordService.updatePassword(values.id, values).then(data => {
        props.editHandler(data);
        formik.resetForm();
        setLoading(false);
      }).catch(error => {
        formik.errors.name = error.message;
        setLoading(false);
      });
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    props.editHandler(null);
  };

  const handleGroupChange = (e) => {
    setInitialValues({ ...initalValues, group: e.target.value })
  };

  useEffect(() => {
    setInitialValues(props.data);
  }, [])

  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-[400px] sm:w-full">
                <form onSubmit={formik.handleSubmit}>
                  <div className="bg-white mx-6 my-3">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 w-full text-centertext-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          Update Password
                        </Dialog.Title>
                        <div className="mt-2 w-full">

                          {/* Password Title */}
                          <div className="">
                            <label
                              className="text-sm text-gray-500"
                              htmlFor="name"
                            >
                              Title
                            </label>
                            <input
                              className="appearance-none block w-full text-sm placeholder:text-sm text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="name"
                              type="text"
                              placeholder="Enter title "
                              name="name"
                              autoComplete="off"
                              value={formik.values.name}
                              onChange={formik.handleChange}
                            />
                            <p className="text-red-500 text-xs mt-1 mb-2">
                              {formik.errors.name
                                ? formik.errors.name
                                : ""}
                            </p>
                          </div>

                          {/*  Username */}
                          <div className="">
                            <label
                              className="text-sm text-gray-500"
                              htmlFor="name"
                            >
                              Username
                            </label>
                            <input
                              className="appearance-none block w-full text-sm placeholder:text-sm text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="username"
                              type="text"
                              placeholder="Enter username"
                              name="username"
                              autoComplete="off"
                              value={formik.values.username}
                              onChange={formik.handleChange}
                            />
                            <p className="text-red-500 text-xs mt-1 mb-2">
                              {formik.errors.username
                                ? formik.errors.username
                                : ""}
                            </p>
                          </div>

                          {/* Password */}
                          <div className="">
                            <label
                              className="text-sm text-gray-500"
                              htmlFor="name"
                            >
                              Password
                            </label>
                            <input
                              className="appearance-none block w-full text-sm placeholder:text-sm text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="password"
                              type="password"
                              name="password"
                              autoComplete="off"
                              placeholder="Enter password"
                              value={formik.values.password}
                              onChange={formik.handleChange}
                            />
                            <p className="text-red-500 text-xs mt-1 mb-2">
                              {formik.errors.password
                                ? formik.errors.password
                                : ""}
                            </p>
                          </div>

                          {/* Group */}
                          <div>
                            <label
                              htmlFor="group"
                              className="text-sm text-gray-500">
                              Select group
                            </label>
                            <select
                              id="group"
                              name="group"
                              value={formik.values.group}
                              onChange={handleGroupChange}
                              className="appearance-none block w-full text-sm placeholder:text-gray-400 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 disabled:bg-gray-100 "
                              placeholder="Select group"
                              disabled={initalValues.owners.length > 1}
                            >
                              <option value={""}>Select group</option>
                              {groups.map(group => {
                                return <option value={group.id} key={group.id}>{group.name}</option>
                              })}
                            </select>

                            <p className="text-gray-500 text-xs mt-2">
                              {initalValues.owners.length > 1 ? 'The shared group cannot be edited.' : null}
                            </p>

                            <p className="text-red-500 text-xs mt-1 mb-2">
                              {formik.errors.group
                                ? formik.errors.group
                                : ""}
                            </p>
                          </div>

                          {/* Website */}
                          <div className="">
                            <label
                              className="text-sm text-gray-500"
                              htmlFor="website"
                            >
                              Website
                            </label>
                            <input
                              className="appearance-none block w-full text-sm placeholder:text-sm text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="website"
                              type="text"
                              placeholder="e.g www.example.com"
                              name="website"
                              autoComplete="off"
                              value={formik.values.website}
                              onChange={formik.handleChange}
                            />
                            <p className="text-red-500 text-xs my-2">
                              {formik.errors.website
                                ? formik.errors.website
                                : ""}
                            </p>
                          </div>

                          {/* Note */}
                          <div className="">
                            <label
                              className="text-sm text-gray-500"
                              htmlFor="note"
                            >
                              Note
                            </label>
                            <textarea
                              className="appearance-none block w-full text-sm placeholder:text-sm text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="note"
                              type="text"
                              placeholder="Enter note"
                              name="note"
                              autoComplete="off"
                              rows={6}
                              value={formik.values.note}
                              onChange={formik.handleChange}
                            />
                            <p className="text-red-500 text-xs my-2">
                              {formik.errors.note
                                ? formik.errors.note
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 flex justify-between">
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-primary-800 hover:bg-primary-900 text-white py-2 px-4 rounded text-sm"
                    >
                      {loading ? <LoadingSpinner /> : "Update"}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
