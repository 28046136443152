import React from 'react'
import { useEffect } from 'react'

export default function TableSkeleton({ rowCount = 10, colCount = 5, show = true }) {
    const [rows, setRows] = React.useState([])
    const [cols, setCols] = React.useState([])

    useEffect(() => {
        let rows = Array(rowCount).fill(0);
        let cols = Array(colCount).fill(0);

        setRows(rows);
        setCols(cols);
    }, [])


    return (
        <>
            {show && (
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            {cols.map((col, index) => (
                                <th
                                    key={`heading-${colCount}-${index}.${col}`}
                                    scope="col"
                                    className="px-6 py-4"
                                >
                                    <div className="h-6 animate-pulse  bg-gray-200 w-full "></div>
                                </th>
                            ))}
                        </tr>
                    </thead>

                    < tbody >
                        {
                            rows.map((row, index) => (
                                <tr
                                    key={`${rowCount}-${index}.${row}`}
                                    className="bg-white border-b"
                                >
                                    {cols.map((col, index) => (
                                        <td
                                            key={`row-${colCount}-${index}.${col}`}
                                            scope="row"
                                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                                        >
                                            <div className="h-3 animate-pulse rounded bg-gray-200 w-full "></div>
                                        </td>
                                    ))}
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            )}
        </>
    )
}
