import React, { useState } from "react";
import ReactGA from "react-ga4";
import { TbClipboardText } from "react-icons/tb";
import { MdContentCopy } from "react-icons/md";

function CopyToClipboard({ textToCopy, size = 25, textVersion = false }) {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(textToCopy).then(
            () => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 1000);
            },
            (err) => {
                console.log("failed to copy", err.message);
            }
        );
        ReactGA.event({
            category: "feature",
            action: "Copy to clipboard",
        });
    };

    return (
        <span onClick={copyToClipboard} className="px-2 cursor-pointer flex justify-center align-middle items-center text-gray-500">
            {
                !copied
                    ? <>{textVersion ? 'Copy' : <MdContentCopy size={size} />}</>
                    : <>{textVersion ? 'Copied!' : <TbClipboardText size={size} />}</>
            }
        </span>
    );
}

export default CopyToClipboard;