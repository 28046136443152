import { SHARED_PASSWORD_LINK } from "../constants/appNavigations";

/**
 * Convert date to human readable format
 * @param {date object} inputDate
 * @returns {string} local date time
 */
export function dateFormatter(inputDate) {
  let date = new Date(inputDate*1000);
  return date.toLocaleString();
}

export function getFullPasswordSharingLink(passwordLinkId) {
  return `${window.location.origin}${SHARED_PASSWORD_LINK}/${passwordLinkId}`
}

/**
 * Convert a string to a Capitalized string
 * @param {string} string
 * @returns {string} Capitalized string
 */
export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getCurrentTimestamp() {
  return Math.floor((new Date()).getTime()/1000);
}

export function getFutureTimeStamp(minutes){
  const date = new Date(new Date().getTime() + (minutes * 60 * 1000))
  return Math.floor(date.getTime()/1000);
}

export function getContentTitle(path) {
  switch (path) {
    case "/app/dashboard":
      return "Dashboard";
    default:
      break;
  }
  return "";
}

export const getFullWebsiteLink = (website) => {
  if (website) {
    if (website.startsWith('http://') || website.startsWith('https://')) {
      return website;
    } else {
      return `http://${website}`;
    }
  }
}

export const getHostFromURL = (url) => {
  const _url = new URL(url);
  return _url.host;
}