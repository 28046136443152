import * as utils from "../utils";
import {
    collection,
    doc,
    query,
    where,
    addDoc,
    getDocs,
    getDoc,
    updateDoc,
    deleteDoc,
    writeBatch,
} from "firebase/firestore";
import { db } from "../libs/firebase";
import userServices from "./userServices";
import { PASSOWRDS_COLLECTION, PASSWORD_LINKs } from "./collections";
import groupService from "./groupService";

const passwordRef = collection(db, PASSOWRDS_COLLECTION);
const passwordLinksRef = collection(db, PASSWORD_LINKs);

async function createPassword(data) {
    const user = userServices.getUser();
    data['createdByEmail'] = user.email;
    data['createdByName'] = user.name;
    data['createdAt'] = utils.getCurrentTimestamp();

    data['modifiedByEmail'] = user.email;
    data['modifiedByName'] = user.name;
    data['modifiedAt'] = utils.getCurrentTimestamp();
    data['owners'] = [user.email];

    if (data.group) {
        const group = await groupService.getGroup(data.group);
        data['owners'] = group.owners;
    }
    
    if (data.website){
        const url = utils.getFullWebsiteLink(data.website);
        data['host']= utils.getHostFromURL(url)
    }

    const response = await addDoc(passwordRef, data);
    return response;
}

// async function listPasswords(group) {
//     const user = userServices.getUser();
//     let password_query = passwordRef;

//     if (Boolean(group)) {
//         password_query = query(
//             passwordRef,
//             where("owners", "array-contains", user.email),
//             where("group", "==", group)
//         );
//     } else {
//         password_query = query(
//             passwordRef,
//             where("owners", "array-contains", user.email)
//         );
//     }

//     const querySnapshot = await getDocs(password_query,);
//     const passwords = querySnapshot.docs.map(doc => {
//         let data = doc.data();
//         data['id'] = doc.id;
//         return data;
//     });
//     return passwords;
// }

async function updatePassword(id, data) {
    const user = userServices.getUser();
    data['modifiedByEmail'] = user.email;
    data['modifiedByName'] = user.name;
    data['modifiedAt'] = utils.getCurrentTimestamp();

    if (data.group) {
        const group = await groupService.getGroup(data.group);
        data['owners'] = group.owners;
    }
    
    if (data.website){
        const url = utils.getFullWebsiteLink(data.website);
        data['host']= utils.getHostFromURL(url)
    }
    
    const docRef = doc(db, PASSOWRDS_COLLECTION, id);
    await updateDoc(docRef, data);
    return await getPassword(id);
}

async function deletePassword(id) {
    const docRef = doc(db, PASSOWRDS_COLLECTION, id);
    return await deleteDoc(docRef);
}

async function getPassword(id) {
    const docRef = doc(db, PASSOWRDS_COLLECTION, id);
    let password = await getDoc(docRef);
    let data = password.data();
    data['id'] = password.id;
    return data;
}

async function syncPasswordOwnersWithGroup(groupId, owners) {
    let password_query = query(
        passwordRef,
        where("group", "==", groupId),
    );

    const querySnapshot = await getDocs(password_query);
    const batch = writeBatch(db);
    querySnapshot.docs.map(async document => {
        const docRef = doc(db, PASSOWRDS_COLLECTION, document.id);
        batch.update(docRef, { 'owners': owners });
    });
    batch.commit();
}

async function removePasswordsWithGroup(groupId) {
    let password_query = query(
        passwordRef,
        where("group", "==", groupId),
    );

    const querySnapshot = await getDocs(password_query);
    const batch = writeBatch(db);
    querySnapshot.docs.map(async document => {
        const docRef = doc(db, PASSOWRDS_COLLECTION, document.id);
        batch.delete(docRef);
    });
    batch.commit();
}

async function removeOwnerFromPasswordsWithGroup(groupId, owner) {

    let password_query = query(
        passwordRef,
        where("group", "==", groupId),
    );

    const querySnapshot = await getDocs(password_query);
    const batch = writeBatch(db);
    querySnapshot.docs.map(async document => {
        let password = document.data();
        password.owners.pop(owner);
        const docRef = doc(db, PASSOWRDS_COLLECTION, password.id);
        batch.update(docRef, { 'owners': password.owners });
    });
    batch.commit();
}

async function generatePasswordLink(passwordId, duration, oneTimeView) {
    const user = userServices.getUser();
    const expiresOn = utils.getFutureTimeStamp(duration); 
    const data = {
        'createdByEmail': user.email,
        'createdByName': user.name,
        'createdAt': utils.getCurrentTimestamp(),
        'duration': Number(duration), // In Minutes
        'oneTimeView': oneTimeView,
        'password': passwordId,
        'viewed': false,
        'expiresOn': expiresOn,
    }
    const passwordLinkDoc = await addDoc(passwordLinksRef, data);
    const passwordLink = utils.getFullPasswordSharingLink(passwordLinkDoc.id);
    return {
        link: passwordLink,
        expiresOn: expiresOn,
    }
}

async function getPasswordLink(id) {
    const docRef = doc(db, PASSWORD_LINKs, id);
    let passwordLink = await getDoc(docRef);
    let data = passwordLink.data();
    data['id'] = passwordLink.id;
    return data;
}

async function updatePasswordLink(id, data) {
    const docRef = doc(db, PASSWORD_LINKs, id);
    await updateDoc(docRef, data);
    return await getPasswordLink(id);
}

async function deletePasswordLink(id) {
    const docRef = doc(db, PASSWORD_LINKs, id);
    return await deleteDoc(docRef);
}

export default {
    createPassword,
    // listPasswords,
    updatePassword,
    deletePassword,
    getPassword,
    syncPasswordOwnersWithGroup,
    removePasswordsWithGroup,
    removeOwnerFromPasswordsWithGroup,
    passwordRef,
    passwordLinksRef,
    generatePasswordLink,
    getPasswordLink,
    updatePasswordLink,
    deletePasswordLink,
};