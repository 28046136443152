import { PasswordList } from "../../components/Passwords";
import { GroupList } from "../../components/Groups";
import AppContextProvider from "../../contexts";
import ReactGA from "react-ga4";

export default function Home() {
    ReactGA.event({
        category: "feature",
        action: "Dashboard view",
    });

    return (
        <AppContextProvider>
            <div className="flex flex-col mx-auto">
                <div className="grid grid-cols-8 gap-4">
                    <div className="col-span-2" >
                        <GroupList />
                    </div>

                    <div className="col-span-6">
                        <PasswordList />
                    </div>
                </div>
            </div>
        </AppContextProvider>
    );
}